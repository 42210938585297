import React from "react";
import IFrame from "react-iframe";

import "./ElementorContainer.css";

//url="https://vagas.byintera.com/madeiramadeira/?cr=comunidades&th=amandinha&utm_source=linkedin&utm_medium=feed&utm_content=Grupos_Linkedin&utm_campaign=madeiramadeira_d_bes_s-sr_a-back-end_id-625&hunter=amandinha"

const ElementorContainer = (props) => {
  const hash = props.match.params.hash;
  const redirURL = "https://byintera.in/" + hash;

  return <IFrame url={redirURL} width="100%" height="100%" />;
};

export default ElementorContainer;
